
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { Form } from 'vee-validate';
import FormField from '@/components/forms/FormField.vue';
import toasts from '@/utils/toasts';
import { Actions, Getters } from '@/store/enums/PromoEnums';
import { Promo } from '@/models/PromoModel';
import { PromoSchema } from '@/schemas/promo.schemas';

import DurationSelect from '@/components/forms/components/DurationSelect.vue';

const initialValues = {
  name: '',
  code: null,
  amount_off: null,
  precent_off: null,
  duration: 'once',
  duration_in_months: null,
  image: null,
} as unknown as Promo;

export default defineComponent({
  name: 'promo-create-form',
  emits: ['dataSubmitted', 'cancelForm'],
  props: {
    prefix: {
      type: String,
      default: '',
    },
  },
  components: {
    Form,
    FormField,
    DurationSelect,
  },
  data: () => ({
    loading: false,
    isLoad: false,
    form: {} as typeof Form,
    formData: initialValues,
  }),
  async mounted() {
    this.form = this.$refs.createPromoForm as typeof Form;
  },
  computed: {
    ...mapGetters({
      actionErrors: Getters.GET_ACTION_ERRORS,
    }),

    formSchema() {
      return PromoSchema;
    },
  },
  methods: {
    ...mapActions({
      create: Actions.CREATE_PROMO,
    }),

    handleCancel() {
      this.resetForm();

      this.$emit('cancelForm');
    },

    resetForm() {
      this.form.resetForm();

      // Reset the form
      this.formData = initialValues;
    },
    async submitForm(values) {
      if (this.loading) return;

      this.loading = true;

      this.formData = values;

      return this.create(this.formData)
        .then(() => {
          this.loading = false;
          this.resetForm();

          this.$emit('dataSubmitted');
        })
        .catch(() => {
          this.loading = false;
          if (this.actionErrors) {
            const { errors, message } = this.actionErrors;

            // Alert when creation request failed
            toasts.error(errors, message);
          }
        });
    },
    handleImageChange(value) {
      this.setFieldValue('image', value);
    },
    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
    addPrefix(name, prefix = '') {
      return prefix ? `${prefix}_${name}` : name;
    },

    handleSelectDuration(value) {
      this.setFieldValue('duration', value);
    },
    handleCloseDuration() {
      this.validateFormField('duration');
    },
  },
});
