
import { defineComponent } from 'vue';
import Card from '@/views/new-design/components/Card.vue';
import DataTable from '@/components/datatables/KTDataTable.vue';
import { Actions, Getters, Mutations } from '@/store/enums/PromoEnums';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Chip from '@/components/Chip.vue';
import useBreakpoints from 'vue-next-breakpoints';

import PromoCreateModal from '@/components/promos/PromoCreateModal.vue';
import PromoUpdateModal from '@/components/promos/PromoUpdateModal.vue';

import { Promo } from '@/models/PromoModel';
import toasts from '@/utils/toasts';
import { capitalize } from '@/utils/text';

export default defineComponent({
  name: 'promo-table',
  components: {
    Card,
    DataTable,
    Chip,
    PromoCreateModal,
    PromoUpdateModal,
  },
  data: () => ({
    promoTable: '',
    loading: false,
    checkedCustomers: [],
    table: {
      total: 0,
      loading: false,
      items: [],
      search: '',
    },
    showCreateModal: false,
    showUpdateModal: false,
  }),
  computed: {
    ...mapGetters({
      allData: Getters.GET_ALL_PROMOS,
      pagination: Getters.GET_PAGINATION,
      actionErrors: Getters.GET_ACTION_ERRORS,
    }),
    isLoading() {
      return this.loading ? this.loading : false;
    },
    dataText() {
      return this.table.total > 1 ? 'promos' : 'promo';
    },
    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    tableHeaders() {
      return [
        {
          name: 'Name',
          key: 'name',
          sortable: true,
        },
        {
          name: 'Code',
          key: 'code',
          sortable: true,
        },
        {
          name: 'AU$ Off',
          key: 'amount_off',
          sortable: true,
          hidden: this.isMobile,
          align: 'center',
        },
        {
          name: '% Off',
          key: 'percent_off',
          sortable: true,
          hidden: this.isMobile,
          align: 'center',
        },
        {
          name: 'Status',
          key: 'status',
          sortable: true,
          hidden: this.isMobile,
          align: 'center',
        },
        {
          name: 'Action',
          key: 'action',
          sortable: true,
          align: 'center',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchAllData: Actions.FETCH_ALL_PROMOS,
      createData: Actions.CREATE_PROMO,
      updateData: Actions.UPDATE_PROMO,
      deleteData: Actions.DELETE_PROMO,
    }),
    ...mapMutations({
      setpromo: Mutations.SET_PROMO,
    }),
    capitalFirst(str) {
      return capitalize(str);
    },
    formatList() {
      return this.allData.map((item) => {
        return {
          id: item.id,
          name: item.name,
          code: item.code,
          quantity: item.quantity,
          amount_off: item.amount_off,
          percent_off: item.percent_off,
          description: item.description,
          max_redemptions: item.max_redemptions,
          duration: item.duration,
          duration_in_months: item.duration_in_months,
          image: item.image,
          status: item.status == 'active' ? 'active' : 'inactive',
        };
      });
    },
    async loadData() {
      if (this.loading) return;
      this.loading = true;

      return await this.fetchAllData()
        .then(async () => {
          this.table.items = await this.formatList();
          this.table.total = this.pagination.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => (this.loading = false));
    },
    getStatus(data: Promo) {
      if (data?.status === 'active') {
        return {
          type: 'success',
          text: 'Active',
          value: true,
          textStatus: '',
        };
      }

      return { type: 'warning', text: 'Inactive', value: false };
    },

    onSearch(search) {
      this.table.search = search;
    },

    async viewDetail(row: Promo) {
      return this.$router.push({
        name: 'view-promo-details',
        params: { promo_id: row.id },
      });
    },

    async editDetail(row: Promo) {
      this.setpromo(row);

      this.showUpdateModal = true;
    },

    confirmDeletepromo(row: Promo) {
      return toasts.confirm(
        `Are you sure you want to delete promo? You can't undo this action...`,
        (response) => {
          if (response.isConfirmed) {
            this.deletepromo(row);
          }
        }
      );
    },

    deletepromo(row: Promo) {
      this.deleteData(row.id)
        .then(() => {
          toasts.success('Promo Successfully Deleted');

          this.loadData();
        })
        .catch(() => {
          this.loading = false;
          const { errors, message } = this.actionErrors;

          toasts.error(errors, message);
        });
    },

    handleFormSubimitted() {
      this.showCreateModal = false;
      this.showUpdateModal = false;
      this.loadData();
    },

    toggleNew() {
      this.showCreateModal = true;
    },

    handleFormCancelled() {
      this.showCreateModal = false;
      this.showUpdateModal = false;
    },
  },

  async mounted() {
    await this.loadData();
  },
});
