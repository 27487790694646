
import { defineComponent } from 'vue';
import Modal from '@/components/modals/Modal.vue';
import PromoUpdateForm from '@/components/promos/PromoUpdateForm.vue';

export default defineComponent({
  name: 'agency-update-modal',
  emits: ['update:modelValue', 'formSubmitted', 'close'],
  props: {
    modelValue: { type: Boolean, default: false },
  },
  components: {
    Modal,
    PromoUpdateForm,
  },
  computed: {
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    handleDataSubmitted() {
      this.$emit('formSubmitted');
    },

    handleClose() {
      this.$emit('close');
    },
  },
});
