
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'chip-status',
  props: {
    type: {
      type: String,
      default: 'success',
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    badgeClass() {
      if (this.type == 'success') {
        return 'badge-light-success';
      } else if (this.type == 'primary') {
        return 'badge-light-primary';
      } else if (this.type == 'danger') {
        return 'badge-light-danger';
      } else if (this.type == 'secondary') {
        return 'badge-light-secondary text-dark';
      } else if (this.type == 'dark') {
        return 'badge-light-dark';
      } else if (this.type == 'light') {
        return 'badge-light-light text-secondary';
      } else if (this.type == 'warning') {
        return 'badge-light-warning';
      }

      return 'badge-light-success';
    },
  },
});
