import { createValidationObjectSchema } from '@/utils/validations';

export const PromoSchema = createValidationObjectSchema({
  name: {
    errorTextFieldLabel: 'name',
    required: true,
  },
  code: {
    errorTextFieldLabel: 'code',
    required: true,
  },
  quantity: {
    errorTextFieldLabel: 'quantity',
    required: true,
  },
  duration: {
    errorTextFieldLabel: 'promo duration',
    required: true,
  },
});
