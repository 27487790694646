
import { defineComponent } from 'vue';

import FormField from '@/components/forms/FormField.vue';

export default defineComponent({
  name: 'duration-select',
  components: {
    FormField,
  },
  emits: ['change', 'close'],
  props: {
    containerClass: {
      default: 'col-lg-4',
      type: String,
    },
    name: {
      default: 'plan',
      type: String,
    },
    label: {
      default: 'Plan',
      type: String,
    },
    value: {
      default: '',
      type: String,
    },
  },
  data: () => ({
    durationOptions: [
      {
        label: 'Forever',
        value: 'forever',
      },
      {
        label: 'Once',
        value: 'once',
      },
      {
        label: 'Repeating',
        value: 'repeating',
      },
    ],
  }),
  methods: {
    handleChange(value) {
      this.$emit('change', value);
    },
    handleClose() {
      this.$emit('close');
    },
  },
});
